<template>
    <section class="page_wrapper">
        <div class="container">
            <div class="page_wrap animate__animated animate__fadeInUp animate__delay-1s">
                <!-- special title -->
                <div class="title_wrapper text-center py-5">
                    <div class="h2_title">
                        <h2 class="text-uppercase">My projects</h2>
                        <div class="title_element_h2"></div>
                    </div>
                </div>
                <!-- special title -->
                <!-- projects row -->
                <div class="project_row">
                    <div class="row row-cols-1 row-cols-xl-2">
                         <div v-for="(project,index) in projects" :key="index" class="col">
                            <!-- CARD -->
                            <div class="project_card my-4">
                                <div class="p_img" :style="{'background-image': 'url(' + require(`../assets/${project.path}`) + ')'}">
                                    <!-- <img :src="require(`../assets/${project.path}`)" :alt="project.title"> -->
                                </div>
                                <div class="p_content text-center my-5">
                                    <h4 class="fs-2 my-3">{{project.title}}</h4>
                                    <truncate class="my-4 p_description fs-4" type="html" clamp="see" :length="90" less="close" :text="project.description"></truncate>
                                    <!-- <div class="my-4">{{contentLenght(project.description,130)}}</div> -->
                                    <a class="second_btn my-5" :href="project.href" target="blank">see the code <i class="fab fa-github ml-2"></i></a>
                                </div>
                            </div>
                            <!-- CARD -->  
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <!-- end container -->
    </section>
</template>

<script>
import truncate from 'vue-truncate-collapsed';

export default {
    name:'Project',
    components:{
        truncate,
    },
    data:function(){
        return{
            projects:[
                {
                    'title':'Deliveboo',
                    'description':'This is a web platform inspired to apps like Deliveroo. It is made with LARAVEL back office side and Vue-js front office side,styled with SASS.This is a single page application,full responsive - mobile first. It is implemented with BRAIN TREE payment system and MAIL TRAP. A User, can register his restaurant and add,edit and delete dishes. A customer can add dishes to a cart and procede to payment. The mail with order details will be sent to user and one to a customer with the delivery time. ',
                    'path':'deliveboo-1.png',
                    'href':'https://github.com/Deki97/deliveboo-48-team3'
                },
                {
                    'title':'Boolflix',
                    'description':'This is a web platform inspired from Netflix built with Vue.js and styled with SASS. It has pagination, search option and card like items where details are displayed as the mouse roll over.',
                    'path':'boolflix-ok.png',
                    'href':'https://github.com/hexeva/vue-boolflix'
                },
                {
                    'title':'DC-Comics',
                    'description':'This is a DC Comics website reproduction built with Vue.js and styled with SASS.',
                    'path':'dc-comics.png',
                    'href':'https://github.com/hexeva/vue-dc-comics'
                },
                {
                    'title':'Rick & Morty',
                    'description':'This is a website made with Vue.js and styled with SASS where the images and card\'s info are called using AXIOS.',
                    'path':'rick-morty.png',
                    'href':'https://github.com/hexeva/rick-e-morty'
                },
                {
                    'title':'Vue-Dischi',
                    'description':'This is a website made with Vue.js and styled with SASS where the images and card\'s info are called using AXIOS.',
                    'path':'vue-dischi.png',
                    'href':'https://github.com/hexeva/vue-dischi'
                },
                {
                    'title':'NexGen Logistic',
                    'description':'This is a website made with Vue.js and styled with SASS. Navbar and Footer are dinamically generated with vue-for.',
                    'path':'logistic-1.png',
                    'href':'https://github.com/hexeva/proj-html-vuejs'
                },
                {
                    'title':'WhatsApp Web',
                    'description':'This is a Whatsapp interactive reproduction built with plain HTML5, CSS and JavaScript. You can send messages (you will get a random response), search for contacts and add more, and delete messages.',
                    'path':'boolzapp.png',
                    'href':'https://github.com/hexeva/vue-boolzapp'
                },
                {
                    'title':'Playstation',
                    'description':'This is the replica of the Playstation website homepage Desktop-first.It\'s made with HTML and CSS.Built using BOOTSTRAP 5.',
                    'path':'playstation-1.png',
                    'href':'https://github.com/hexeva/htmlcss-playstation'
                },
                {
                    'title':'Spotify Web',
                    'description':'This is platform inspired from Spotify Web built with just HTML and CSS. Fully responsive.',
                    'path':'spotify-web.png',
                    'href':'https://github.com/hexeva/html-css-spotifyweb'
                },
                {
                    'title':'Dropbox',
                    'description':'This is platform inspired from Spotify Web built with just HTML and CSS.No frameworks or web tools were used.',
                    'path':'dropbox.png',
                    'href':'https://github.com/hexeva/htmlcss-dropbox'
                },


            ],
        }
    },
    // METHODS
    methods:{
        // TAGLIA IL TESTO
         contentLenght: function(text,lengthText){
            if(text.length > lengthText){
               return text.substr(0,lengthText) + '...'
            }else{
                return text;
            }
        },
    },
   
    
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';

.page_wrap{
    width: 100%;
    background-color: $background;
    border: 2px solid $special_color;
    border-radius: 20px;
    .project_card{
        padding:10px 20px;
        @media (max-width:400px) {
            padding:10px 8px;
        }
        .p_content{
            @media (max-width:400px) {
                font-size: 15px;
            }
             
        }
    }


    .p_img{
        height: 300px;
        overflow: hidden;
        background-position: inherit;
        background-size: cover;
        background-origin: content-box;
        box-shadow: 3px 3px 3px 3px $special_color;
        border-radius: 35px;
        cursor: pointer;
        
    }
    .p_img:hover{
        transform:scale(1.5);
        transition-delay: 500ms;
        }

        
    
}
</style>