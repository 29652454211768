<template>
    <header>
        <!-- NAV BAR -->
        <nav class=" my_nav navbar navbar-expand-lg align-items-center justify-content-center">
            <div class="menus d-flex align-items-center">
                <!-- Logo -->
                <div class="header-logo">
                    <!-- <img src="../../assets/img/fast-food.png" alt="Deliveboo Logo"> -->
                </div>

                <!-- Desktop Menu -->
                <div class="collapse navbar-collapse align-items-center" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item active mx-5" v-for="(link,index) in nav_menu" :key="index">
                            <router-link class="nav-link nav_style" :to="{name:link.href}" active-class="active-link">{{link.name}}</router-link>
                        </li>
                    </ul>
                </div>

                <!-- Burger Menu -->
                <div class="burger my_burger d-flex flex-column">
                    <Slide class="d-block d-lg-none burger-menu align-self-center" right
                    :isOpen="this.open"
                    :closeOnNavigation="true"
                    noOverlay
                    width="250"
                    >

                    <!-- Burger Links -->
                    <li v-for="(link,index) in nav_menu" :key="index">
                        <router-link class="nav-link nav_style" :to="{name:link.href}" active-class="active-link">{{link.name}} <i class="burger_icons" :class="link.icon"></i></router-link>
                    </li>
                    </Slide>
                </div>
            </div>  
        </nav>
        <!-- END NAV BAR -->
        
    </header>
</template>

<script>
import { Slide } from 'vue-burger-menu';
export default {
    name:'Header',
    // COMPONENTS
    components:{
        Slide,
    },
    // DATA
    data:function(){
        return {
            open: false,

            nav_menu:[
                {
                    'href':'home',
                    'name':'Home',
                    'icon':'fas fa-home'
                },
                {
                    'href':'about',
                    'name':'About',
                    'icon':'fas fa-user-secret'
                },
                {
                    'href':'project',
                    'name':'My Projects',
                    'icon':'fas fa-project-diagram'
                },
                {
                    'href':'contact',
                    'name':'Contacts',
                    'icon':'fas fa-envelope'
                },
            ],
            
        }
    },
    // END DATA
    // METHODS
    methods:{
        openMenu() {
            this.open = true;
            },
    },
    
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';

header{
    background-color: rgb(113,26,117);
    background: linear-gradient(0deg, rgba(113,26,117,1) 0%, rgba(177,119,151,1) 4%, rgba(24,10,10,1) 13%);
    width: 100%;
    .my_nav{
        padding:51px 0; 
    }
       .burger_icons{
           font-size: 18px;
           margin-left: 30px;
       }
     
}
// end HEADER




</style>