<template>
    <div class="container">
        <div class="home">
            <div class="row">
                <div class="col col-12 col-lg-7">
                    <Banner />
                </div>
                <div class=" col d-none col-lg-5 d-lg-block">
                    <Me />
                </div>
            </div>
            <div class="find_me">
                <a class="main_btn special" href="https://it.linkedin.com/in/patrick-galasso-092045216?trk=people-guest_people_search-card" target="blank">
                    <i class="fab fa-linkedin"></i>
                    Find me on Linkedin
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import Banner from "../components/Banner.vue";
import Me from "../components/Me.vue";
export default {
    name:'Home',
    components:{
        Banner,
        Me,

    },
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';

.home{
    width: 100%;
    height: 100%;
    padding: 180px 0;
    @media (max-width:900px) {
        padding-top: 60px;
        padding-bottom: 150px;
    }
    
    .find_me{
        width: 100%;
        position:relative;
        .special{
            position: absolute;
            top: -80px;
            left: 500px;
            @media (max-width:1200px) {
                top:40px;
                left: 295px;
            }
            @media (max-width:992px) {
                top:40px;
                left: 227px;
            }
            @media (max-width:768px) {
                top:40px;
                left: 128px;
            }
            @media (max-width:550px) {
                top:40px;
                left: 90px;
            }
            @media (max-width:450px) {
                top:40px;
                left: 90px;
                font-size: 15px;
            }
            @media (max-width:400px) {
                top:40px;
                left: 70px;
                font-size: 15px;
            }
            @media (max-width:350px) {
                top:40px;
                left: 47px;
                font-size: 15px;
            }
            i{
            margin-right: 5px;
            }
        }
        
    }
}



</style>