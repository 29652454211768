<template>
    <section class="page_wrapper">
        <div class="contact animate__animated animate__fadeInUp animate__delay-1s">
            <!-- special title -->
            <div class="title_wrapper text-center py-5">
                <div class="h2_title">
                    <h2 class="text-uppercase">How to Contact me</h2>
                    <div class="title_element_h2"></div>
                </div>
            </div>
            <!-- special title -->
            <div class="links">
                <div class="link animate__animated animate__fadeInRight animate__delay-2s"> <i class="fab fa-linkedin"></i> LinkedIn: <br>
                <a href="https://it.linkedin.com/in/patrick-galasso-092045216?trk=people-guest_people_search-card" target="blank">Patrick Galasso</a></div>
                <div class="link animate__animated animate__fadeInRight animate__delay-3s"> <i class="fab fa-github"></i> GitHub: <br>
                <a href="https://github.com/hexeva" target="blank">hexeva</a></div>
                <div class="link animate__animated animate__fadeInRight animate__delay-4s"> <i class="fas fa-at"></i> Email: <br>
                <a href="mailto: postmaster@patrick-developer.eu" target="blank">postmaster@patrick-developer.eu</a></div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name:'Contact',
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';

.contact{
     width:80%;
        margin: auto;
        height: 100%;
        background-color: $background;
        // test
        border: 2px solid $special_color;
        border-radius: 20px;
        @media (max-width:600px) {
            width: 100%;
        }

        .links{
            width: 80%;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;
            padding: 30px 0;
            .link{
                font-size: 45px;
                color:$special_color;
                margin: 40px 0;
                @media (max-width:700px) {
                    font-size: 25px;
                }
                i{
                    margin-right: 30px;
                }
                a{
                    text-decoration: none;
                    font-size: 30px;
                    margin-left: 265px;
                    text-decoration: underline;
                    color:$title_text;
                    @media (max-width:1110px) {
                        margin-left: 20px;
                    }
                @media (max-width:700px) {
                        font-size: 15px;
                    }
                }
                
            }
        }
}

</style>