<template>
    <section class="home_title">
        <h1 class="animate__animated animate__fadeInRight animate__delay-2s">Hi,I am <span class="my_name">Patrick</span></h1>
        <h2 class="animate__animated animate__fadeInLeft animate__delay-3s">I am a
            <vue-typer
                text='Full Stack'
                :repeat='Infinity'
                :shuffle='false'
                initial-action='typing'
                :pre-type-delay='200'
                :type-delay='200'
                :pre-erase-delay='2500'
                :erase-delay='250'
                erase-style='backspace'
                :erase-on-complete='false'
                caret-animation='blink'
            ></vue-typer>
                    <span class="dev"> Developer</span>.</h2>
        <h3 class=" motto animate__animated animate__fadeInDown animate__delay-3s"><span>Passionate</span> about creating interactive applications and experiences on the web.</h3>                
    </section>
    
</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
    name:'Banner',
    components:{
        VueTyper,
    },
}
</script>

<style lang="scss" scoped>
@import '../style/variables';
@import '../style/general';
.home_title{
    margin-top: 100px;
}
.my_name{
    color:$main_color_two;
}
.dev{
    color:$title_text;
}
.motto{
    color: $special_color;
    font-family: revert;
    font-style: italic;
    margin-top: 50px;
    span{
        color:$main_color_two;
    }
}
// COLORE TYPER
::v-deep .typed {
                color:$special_color !important;
            }

</style>