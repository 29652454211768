<template>
    <div class=" my_image animate__animated animate__fadeInRight animate__delay-1s">
        <img src="../assets/patrick.png" alt="Patrick">
    </div>
</template>

<script>

export default {
    name:'Me',
}
</script>

<style lang="scss" scoped>
@import '../style/variables';
@import '../style/general';

.my_image{
    width: 100%;
    img{
        padding-top:20px;
        padding-bottom: 60px;
        position: relative;
        height:initial;
    }
    
}
.my_image::after{
    content:'';
    display: block;
    width: 80px;
    background-color: $title_text;
    height: 10px;
    border-radius: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
}
.my_image::before{
    content:'';
    display: block;
    width: 80px;
    background-color: $title_text;
    height: 10px;
    border-radius: 50px;
    position:absolute;
    top: 0;
    left: 0;
}

</style>