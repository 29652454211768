<template>
    <section class="page_wrapper">
        <div class="about animate__animated animate__fadeInUp animate__delay-1s">
            <div class="about_content">
                <!-- special title -->
                <div class="title_wrapper text-center py-5">
                    <div class="h2_title">
                        <h2 class="text-uppercase">About me</h2>
                        <div class="title_element_h2"></div>
                    </div>
                </div>
                <!-- special title -->
                <div class="contents">
                    <div class="row row-cols-1 row-cols-xl-2 ">
                        <div class="col">
                            <!-- DESCRIPTION  -->
                            <div class="description mb-5 animate__animated animate__fadeInLeft animate__delay-2s">
                                <!-- <h3 class="about_h3">Who am i</h3> -->
                                <!-- special title -->
                                <div class="title_wrapper text-center py-5">
                                    <div class="h3_title">
                                        <h3 class="text-capitalize">Who i am</h3>
                                        <div class="title_element_h3"></div>
                                    </div>
                                </div>
                                <!-- special title -->
                                <div class="description_content">
                                    <p>Graduated at industrial hight school in Electronic and Telecomunication ,i worked for about 18 years in the biomedical sector. <br>
                                        I have an artistic soul , i love to play instruments , paint and to read fantasy books! After 2018 i started to write some simple HTML and CSS code and...I loved it. <br>
                                        Finally in 2021 i had the chance to study seriously during a hard training schools as <span class="t_special">Full Stack Web Developer</span>. 
                                        The programming world is huge and this is just the beginning of my way!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <!-- SKILLS -->
                            <div class="skills mb-5 animate__animated animate__fadeInRight animate__delay-3s">
                                <!-- <h3 class="about_h3">Skills</h3> -->
                                <!-- special title -->
                                <div class="title_wrapper text-center py-5">
                                    <div class="h3_title">
                                        <h3 class="text-capitalize">skills</h3>
                                        <div class="title_element_h3"></div>
                                    </div>
                                </div>
                                <!-- special title -->
                                <div class="skills_content">
                                    <ul>
                                        <li class="d-flex justify-content-between my-2" v-for="(skill,index) in skills" :key="index" >
                                             <span class="skill_text">{{skill.text}}</span> <span class="skill_icon"><i :class="skill.icon"></i></span>
                                        </li>
                                    </ul>
                                    <h4 class=" frame_title  mx-4 text-uppercase">Frameworks:</h4>
                                    <ul>
                                        <li class="d-flex justify-content-between my-2" v-for="(f_skill,index) in frameworks" :key="index" >
                                             <span class="skill_text" >{{f_skill.text}}</span> <span class="skill_icon"><i :class="f_skill.icon"></i></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->
                    <div class="will text-center">
                        <!-- special title -->
                                <div class="title_wrapper text-center py-5">
                                    <div class="h3_title">
                                        <h3 class="text-capitalize">Next Skills</h3>
                                        <div class="title_element_h3"></div>
                                    </div>
                                </div>
                                <!-- special title -->
                        <p>Actually i'm planning to improve my skills learning new <span class="will_sp">languages</span> and <span class="will_sp">frameworks</span> such as:</p>
                        <div class="next_skills">
                            <ul>
                                <li><i class=" icons fas fa-chevron-right"></i>Java</li>
                                <li><i class=" icons fas fa-chevron-right"></i>React</li>
                                <li><i class=" icons fas fa-chevron-right"></i>Python</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name:'About',
    data:function(){
        return{
            skills:[
                {
                    'text':'Html 5',
                    'icon':'fab fa-html5'
                },
                {
                    'text':'Css',
                    'icon':'fab fa-css3'
                },
                {
                    'text':'Sass / Scss',
                    'icon':'fab fa-sass'
                },
                {
                    'text':'Bootstrap 5',
                    'icon':'fab fa-bootstrap'
                },
                {
                    'text':'Javascript',
                    'icon':'fab fa-js'
                },
                {
                    'text':'Php',
                    'icon':'fab fa-php'
                },
                {
                    'text':'MySQL',
                    'icon':'fas fa-database'
                },
            ],
            frameworks:[
                {
                    'text':'Vue-Js',
                    'icon':'fab fa-vuejs'
                },
                {
                    'text':'Laravel',
                    'icon':'fab fa-laravel'
                },
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';
    

    .about{
        width:80%;
        margin: auto;
        height: 100%;
        background-color: $background;
        // test
        border: 2px solid $special_color;
        border-radius: 20px;
        @media (max-width:600px) {
            width: 100%;
        }
        .contents{
            width: 100%;
            margin-top:70px;
            padding:0 80px;
            @media (max-width:1000px) {
                padding:0 40px;
            }
            @media (max-width:703px) {
                padding:0 20px;
                margin-top: 30px;
            }
            .description_content{
                font-size: 25px;
                @media (max-width:750px) {
                    font-size:20px;
                }
                @media (max-width:703px) {
                    font-size:17px;
                }
                @media (max-width:400px) {
                    font-size:15px;
                }
                .t_special{
                    color: $main_color_two;
                }
            }

            .about_h3{
                text-transform: capitalize;
                margin-bottom: 50px;
            }
            .skills_content{
                padding:0 50px;
                @media (max-width:703px) {
                    padding:0 35px;
                }
                @media (max-width:450px) {
                    padding:0;
                }
                .frame_title{
                    font-size: 25px;
                    @media (max-width:703px) {
                        font-size: 18px;
                    }
                    @media (max-width:400px) {
                        font-size: 13px;
                    }
                }

                .skill_text{
                    font-size: 32px;
                    @media (max-width:900px) {
                        font-size: 28px;
                    }
                    @media (max-width:703px) {
                        font-size: 24px;
                    }
                    @media (max-width:400px) {
                        font-size: 19px;
                    }
                }
                .skill_icon{
                    font-size:35px;
                    color:$main_color_two;
                    @media (max-width:900px) {
                        font-size: 30px;
                    }
                    @media (max-width:703px) {
                        font-size: 26px;
                    }
                    @media (max-width:400px) {
                        font-size: 22px;
                    }
                }
            }
            .will{
                width:100%;
                margin: 70px 0;
                padding-bottom:20px;
                @media (max-width:703px) {
                    font-size:17px;
                    margin: 40px 0;
                }
                @media (max-width:400px) {
                    font-size:15px;
                    margin: 20px 0;
                }
                .will_sp{
                    color:$main_color_two;
                }
                .next_skills{
                    width:100%;
                    text-align: start;
                    padding-left: 50px;
                    margin-top:25px;
                    ul{
                        li{
                            font-size:28px;
                            color:$main_color_two;
                            margin:12px;
                            @media (max-width:700px) {
                                font-size:17px;
                            }
                        }
                    }
                    .icons{
                        padding-right:20px;
                        margin-left:-10px;
                        font-size:23px;
                        color:$special_color;
                        @media (max-width:700px) {
                            font-size:15px;
                        }
                    }
                }
            }
        }
        // end contents
    }


</style>