<template>
    <section class="page_wrapper">
        <div class="error">
            <div class="err_img">
                <i class="far fa-frown-open"></i>
            </div>
            <div class="err_text">
                <span class="sorry text-uppercase">SORRY</span>, page not found!. Go back to  
            </div>
            <div>
                <router-link class="nav-link nav_style extra" to="/" active-class="active-link">Home</router-link> 
            </div>
        </div>
    </section>
    
</template>

<script>

export default {
    name:'NotFound',
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';

.error{
     width:80%;
        margin: auto;
        background-color: $background;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 100px 0;
        // test
        border: 2px solid $special_color;
        border-radius: 20px;
        @media (max-width:600px) {
            width: 100%;
        }
        .err_img{
            i{
                font-size:220px;
                color:$main_color_two;
            }
        }
        .err_text{
            margin: 100px 0;
        }
        .sorry{
            color: $main_color_two;
        }
        .extra{
            border: 1px solid;
            padding: 5px 12px;
            border-radius: 5px;
            font-size: 20px;
        }
}
</style>