<template>
    <footer>
        <section class="footer-top">
           <div class="container">
               <div class="content text-center">
                   <h5>Made with: <span>Vue Js <i class="fab fa-vuejs"></i></span></h5>
               </div>
           </div>
        </section>
        <section class="footer-bottom">
                <div class="credits">
                    <div class="credit_content">
                        <span class="mr-2"><i class="fas fa-copyright"></i></span>
                        <span>Patrick Galasso 2022</span>
                    </div>
                </div>
        </section>
    </footer>
</template>

<script>
export default {
    name:'Footer',
}
</script>

<style lang="scss" scoped>
@import '../style/variables.scss';
@import '../style/general.scss';
@import '../style/common.scss';

footer{
    background: rgb(177,119,151);
    background: linear-gradient(180deg, rgba(177,119,151,1) 1%, rgba(24,10,10,1) 8%);
    .footer-top{
        height: 70px;
        display: flex;
        align-items: center;
        
           h5{
               font-size: 25px;
               @media (max-width:400px) {
                   font-size: 13px;
               }
               span{
                   font-size: 27px;
                   color:$main_color_two;
                   @media (max-width:400px) {
                       font-size: 15px;
                   }
               }
           }

        
    }
    .footer-bottom{
        height: 30px;
        line-height: 29px;
        border-top:1px solid $main_color;
        .credits{
            width: 85%;
            margin: auto;
            .credit_content{
                font-size: 18px;
                @media (max-width:400px) {
                    font-size:9px;
                }
            }
        }
    }
}
</style>